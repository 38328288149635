import request from '@/utils/request'


// 查询设备报警流程列表
export function listDeviceAlarmActivity(query) {
  return request({
    url: '/gather/gather-device-alarm-activity/list',
    method: 'get',
    params: query
  })
}

// 查询设备报警流程分页
export function pageDeviceAlarmActivity(query) {
  return request({
    url: '/gather/gather-device-alarm-activity/page',
    method: 'get',
    params: query
  })
}

// 查询设备报警流程详细
export function getDeviceAlarmActivity(data) {
  return request({
    url: '/gather/gather-device-alarm-activity/detail',
    method: 'get',
    params: data
  })
}

// 新增设备报警流程
export function addDeviceAlarmActivity(data) {
  return request({
    url: '/gather/gather-device-alarm-activity/add',
    method: 'post',
    data: data
  })
}

// 修改设备报警流程
export function updateDeviceAlarmActivity(data) {
  return request({
    url: '/gather/gather-device-alarm-activity/edit',
    method: 'post',
    data: data
  })
}

// 删除设备报警流程
export function delDeviceAlarmActivity(data) {
  return request({
    url: '/gather/gather-device-alarm-activity/delete',
    method: 'post',
    data: data
  })
}
